import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertTransactionHash = createAction('REVERT_ALL')

const initialState = {
  trasaction_hash: null,
}

export const transactionHashReducer = createSlice({
  name: "T-hash",
  initialState,
  extraReducers: (builder) => builder.addCase(revertTransactionHash, () => initialState),
  reducers: {
    add_transaction_hash: (state, action) => action.payload
  }
})

export default transactionHashReducer