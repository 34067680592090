import React, { useState } from 'react'
import iconRight from "../../Images/icons/icon-right.png"
import iconClose from "../../Images/icons/icons8-close-30.png"
import { useSelector } from 'react-redux'
import loadingGif from "../../Images/Animation-gifs/loader.gif"

function VerificationCard({ country_code,input_email ,img, img2, verify_msg, verify_msg1, verify_paragrapgh1, verify_paragrapgh, closeBtn, input_text, email_set, updateVerify, input_mobile_number}) {
    // toggle 
    const [toggle, setToggle] = useState(true)

    // get did 
    const did = useSelector((state) => state.DidReducer)

    // set email and msg 
    const [emailMsg, setEmailMsg] = useState(null)
    const [OTP, setOTP] = useState(null)
    const [code, setCode] = useState(null)
    const [error, setError] = useState("")

    // verification process
    const [verify, setVeriy] = useState({ is_verifying: false, is_complete: false, is_fail: false })

    //  handle change in get code 
    const handleChangeCode = (e) => {
        setCode(e.target.value)
    }

    // submit email and mobile
    const submit = async () => {
        if (email_set) {
            // check if email is valid
            if (!input_email.includes('@')) {
                setError("please enter valid email")

                setTimeout(() => {
                    setError("")
                }, 3000);
                return
            }

            // submit function
            try {
                setVeriy((prev) => ({ ...prev, is_verifying: true }))

                const res = await fetch(process.env.REACT_APP_BACKEND_URL + `save-email-verify`, {
                    method: "POST",
                    body: JSON.stringify({
                        "Did": did,
                        "UserEmail": input_email,
                    })
                })

                if (res.status === 200) {
                    setVeriy((prev) => ({ ...prev, is_verifying: false }))
                    setVeriy((prev) => ({ ...prev, is_complete: true }))
                    setToggle(false)
                }
                else {
                    setVeriy((prev) => ({ ...prev, is_verifying: false }))
                    setError("fail please try again")

                    setTimeout(() => {
                        setError("")
                    }, 2000);
                }
            } catch (error) {
                console.log(error)
                setVeriy((prev) => ({ ...prev, is_verifying: false }))
                setError("fail please try again")

                setTimeout(() => {
                    setError("")
                }, 2000);
            }
        }
        else {
            // submit fn for mobile otp send
            const mobileNumber = country_code + input_mobile_number
            const new_mobile = mobileNumber.split("+").join("")
            console.log(new_mobile)

            // submit function
            try {
                setVeriy((prev) => ({ ...prev, is_verifying: true }))

                const res = await fetch(process.env.REACT_APP_BACKEND_URL + `otp`, {
                    method: "POST",
                    body: JSON.stringify({
                        "DID": did,
                        "Mobile": new_mobile
                    })
                })

                if (res.status === 200) {
                    setVeriy((prev) => ({ ...prev, is_verifying: false }))
                    setVeriy((prev) => ({ ...prev, is_complete: true }))
                    setToggle(false)
                }
                else {
                    setVeriy((prev) => ({ ...prev, is_verifying: false }))
                    setError("fail please try again")

                    setTimeout(() => {
                        setError("")
                    }, 2000);
                }
            } catch (error) {
                console.log(error)
                setVeriy((prev) => ({ ...prev, is_verifying: false }))
                setError("fail please try again")

                setTimeout(() => {
                    setError("")
                }, 2000);
            }

        }


    }

    const submitOTP = async () => {
        if (!OTP) {
            setError("Please Enter OTP")
            return
        }
        if (email_set) {
            // submit  otp function
            try {
                setVeriy((prev) => ({ ...prev, is_verifying: true }))

                const res = await fetch(process.env.REACT_APP_BACKEND_URL + `update-email-verify`, {
                    method: "PUT",
                    body: JSON.stringify({
                        "Did": did,
                        "UserEmail": input_email,
                        "Token": OTP,
                    })
                })

                if (res.status === 200) {
                    setVeriy((prev) => ({ ...prev, is_verifying: false }))
                    setVeriy((prev) => ({ ...prev, is_complete: true }))

                    console.log(await res.json())
                    setToggle(false)

                    setTimeout(() => {
                        updateVerify()
                    }, 1000);

                    closeBtn()

                }
            } catch (error) {
                console.log(error)
                setVeriy((prev) => ({ ...prev, is_verifying: false }))
            }
        }
        else {
            // submit fn for mobile otp send
            // submit  otp function
            try {
                setVeriy((prev) => ({ ...prev, is_verifying: true }))

                const res = await fetch(process.env.REACT_APP_BACKEND_URL + `otp-validate`, {
                    method: "POST",
                    body: JSON.stringify({
                        "DID": did,
                        "OTP": OTP,
                    })
                })

                if (res.status === 200) {
                    setVeriy((prev) => ({ ...prev, is_verifying: false }))
                    setVeriy((prev) => ({ ...prev, is_complete: true }))

                    console.log(await res.json())
                    setToggle(false)

                    setTimeout(() => {
                        updateVerify()
                    }, 1000);

                    closeBtn()

                }
            } catch (error) {
                console.log(error)
                setVeriy((prev) => ({ ...prev, is_verifying: false }))
            }

        }
    }
    return (
        <div className='bg-black fixed justify-center flex flex-col items-center w-[400px] h-[450px] p-2 border-[1px] border-white/50 rounded-lg  left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 lg:ml-[140px]'>
            {/* close button */}
            <button onClick={closeBtn} className='absolute top-2 right-2 rounded-full p-2 border border-white/40 hover:bg-red-600 z-[30]'>
                <img src={iconClose} className='w-[10px]' alt="" />
            </button>

            {/* verfication image */}
            {toggle ?
                <div className='w-full flex flex-col items-center animate__animated animate__fadeIn'>
                    <div className='w-full flex justify-center py-2'>
                        <img src={img} className='w-[200px] grayscale' alt="" />
                    </div>

                    <hr className='w-[90%] opacity-20 text-white py-2' />

                    {/* header text */}
                    <div className='px-2 py-3 text-white w-full flex flex-col items-center tracking-widest'>
                        <h3 className='text-[15px] font-semibold '>{verify_msg}</h3>
                        <p className='text-[12px]'>{verify_paragrapgh}</p>
                    </div>


                    {/* mobile number enter value  */}
                    <div className='w-full px-10 items-center justify-center flex py-3 gap-2'>
                        {/* country code */}
                        {!email_set &&
                            <select disabled onChange={handleChangeCode} value={code} defaultValue={""} className="bg-black-100 w-[100px] border-b-[1px] text-[12px] bg-white/10 text-white py-2 px-2 rounded-lg ">
                                <option value={country_code}>{country_code}</option>
                            </select>
                        }

                        <div className='w-full flex flex-col relative'>
                            <input disabled type={!email_set ? 'number' : 'email'} onChange={(e) => setEmailMsg(e.target.value)} className='outline-none bg-white/10 border-b-[1px] flex text-[12px] w-full text-white rounded-lg px-3 py-2  border-bethel-green placeholder:text-[12px] placeholder:text-white/60' 
                            placeholder={input_text} defaultValue={email_set ? input_email : input_mobile_number} />
                            <div className='text-red-500 font-semibold tracking-widest text-[12px] absolute top-10 left-2'>{error}</div>
                        </div>

                    </div>


                    {/* sbumit and cancel button */}
                    <div className='w-full px-8 flex justify-end gap-3 py-4'>
                        {/* sumbit button */}
                        <button onClick={submit} className='px-2 py-2 rounded-full bg-black border border-white/50 hover:bg-bethel-green/90  text-white hover:text-black font-semibold text-[10px]'>
                            {!verify.is_verifying ? <img src={iconRight} className='w-[20px] hover:scale-110' alt="" /> :
                                <img src={loadingGif} className='w-[20px] hover:scale-110' alt="" />}
                        </button>
                    </div>
                </div>

                :

                <div className='w-full flex flex-col items-center animate__animated animate__fadeIn'>
                    <div className='w-full flex justify-center py-2'>
                        <img src={img2} className='w-[200px] opacity-70' alt="" />
                    </div>

                    <hr className='w-[90%] opacity-20 text-white py-2 ' />

                    {/* header text */}
                    <div className='px-2 py-3 text-white w-full flex flex-col items-center tracking-widest'>
                        <h3 className='text-[15px] font-semibold '>{verify_msg1}</h3>
                        <p className='text-[12px]'>{verify_paragrapgh1}</p>
                    </div>


                    {/* mobile number enter value  */}
                    <div className='w-full px-10 items-center justify-center flex py-3 gap-2'>
                        <input onChange={(e) => setOTP(e.target.value)} type=' ' className='outline-none bg-white/10 border-b-[1px] flex text-[12px] w-full text-white rounded-lg px-3 py-2  border-bethel-green placeholder:text-[12px] placeholder:text-white/60' placeholder={"Enter OTP here"} />
                    </div>


                    {/* sbumit and cancel button */}
                    <div className='w-full px-8 flex justify-end gap-3 py-4'>
                        {/* sumbit button */}
                        <button onClick={submitOTP} className='px-2 py-2 rounded-full bg-black border border-white/50 hover:bg-bethel-green/90  text-white hover:text-black font-semibold text-[10px]'>
                            <img src={iconRight} className='w-[20px] hover:scale-110' alt="" />
                        </button>
                    </div>
                </div>
            }

        </div>
    )
}

export default VerificationCard