import React, { useEffect, useState } from 'react'
import iconCamera from "../../Images/icons/icon-camera.png"
import gifIconDone from "../../Images/icons/icon-done.png"
import { useSelector } from 'react-redux'
import { ethers } from 'ethers'

function SideCardProfileInfo({ image, Name, Email, Discription, onBtnClick, blur }) {

    const [imgUpload, setImageUpload] = useState({ success: false, fail: false })

    const did = useSelector((state) => state.DidReducer)
    const [imgSrc, setImgSrc] = useState()

    const handleFileUpload = async (e) => {
        const file = e.target.files[0]

        const fd = new FormData()
        fd.append("OwnerDid", did)
        fd.append("profile_image", file)

        const res = await fetch(process.env.REACT_APP_BACKEND_URL + "update-user-profile-image", {
            method: "PUT",
            body: fd
        })

        if (res.status === 200) {
            getProfileImage()
            setImageUpload((prev) => ({ ...prev, success: true }))

            setTimeout(() => {
                setImageUpload((prev) => ({ ...prev, success: false }))
            }, 2000);
        }
        else {
            setImageUpload((prev) => ({ ...prev, fail: true }))

            setTimeout(() => {
                setImageUpload((prev) => ({ ...prev, fail: false }))
            }, 2000);
        }
    }

    // get profile image
    const getProfileImage = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const address = await signer.getAddress()

        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + `get-user-profile?OwnerDid=${did}&OwnerAddress=${address}`)
            const resData = await res.json()
            
            const byteCharacters = atob(resData.ProfileImage)
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });
            const imageUrl = URL.createObjectURL(blob);
            setImgSrc(imageUrl);

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getProfileImage()
    }, [])

    return (
        <div className={`flex lg:w-1/4 md:w-1/4 sm:w-full min-[320px]:w-full h-auto p-3 bg-black border-bethel-green/50 border-[1px] mt-2 rounded-lg ${blur ? 'blur-sm' : ""}`}>
            {/* user or company profile photo and name */}
            <div className='w-full flex flex-col gap-2'>
                {/* profile or company photo */}
                <div className='border-[1px] border-white/20 p-2 rounded-lg relative'>
                    <div className='w-full justify-center items-center flex p-2'>
                        <div className='w-full relative justify-center flex'>
                            <img src={imgSrc ? imgSrc : image} className={` ${imgUpload.success === true ? "border-bethel-newgreen border-[8px]" : "border-white border-[4px]"}
                            lg:w-[150px] lg:h-[150px] md:w-[150px] md:h-[150px] sm:w-[150px] sm:h-[150px] min-[320px]:w-[100px] min-[320px]:h-[100px]
                            rounded-full`} alt="" />
                            <input onChange={handleFileUpload} id="file-type" type="file" hidden />

                            <label for="file-type" id="button" className="absolute -bottom-3 cursor-pointer flex flex-row items-center justify-center">
                                <div className={`${imgUpload.success === true ? "bg-green-100" : "bg-white"} p-2 rounded-full items-center`}>
                                    <img src={imgUpload.success === true ? gifIconDone : iconCamera} className='w-[15px]' alt="" />
                                </div>

                            </label>

                        </div>



                    </div>

                    {/* name */}
                    <div className='flex flex-col items-center w-full justify-center mt-2'>
                        <h3 className='text-white text-[16px]'>{Name ? Name : "New User"}</h3>
                        <h3 className='text-white/50 text-[12px]'>{Email ? Email : "Email"}</h3>
                    </div>
                </div>


                {/* description */}
                <div className='flex px-2 flex-col gap-2'>
                    <h3 className='text-white/80 text-[14px] mt-2'>Description :</h3>
                    <p className='text-white/50 text-[12px]'>{Discription ? Discription : "Discription here.."}</p>
                </div>
            </div>

        </div>
    )
}

export default SideCardProfileInfo