import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import iconReferral from "../Images/icons/icon-referrals.png"
import copyIcon from "../Images/icons/icon-copy.png"

function ReferralsComponent() {
    const userDid = useSelector((state) => state.DidReducer)
    const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)
    const inputRef = useRef()
    const [copied, setCopied] = useState(false)

    const [refCount, setRefCount] = useState(null)

    // referral link
    const [refLink, setRefLink] = useState(null)

    const handleCopy = () => {
        if (inputRef.current) {
            navigator.clipboard.writeText(inputRef.current.defaultValue)
                .then(() => {
                    setCopied(true)

                    setTimeout(() => {
                        setCopied(false)
                    }, 1000);
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        }
    };

    const refLinkfn = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-ref-id",{
                method : "POST",
                body : JSON.stringify({
                    "DID" : userDid
                })
            })
            const ref = await res.json()
            setRefLink(`https://mainnet.bethel.network/ref?${ref.RefID}`)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=> {
        refLinkfn()
        getRefCount()
    },[])

    // get referral count 
    const getRefCount = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + "get-counts",{
                method : "POST",
                body : JSON.stringify({
                    "DID" : userDid
                })
            })
            const ref = await res.json()
            setRefCount(ref)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className={`px-2 py-4 ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>
            {/* topic div */}
            <div className='text-white w-full flex items-center gap-1'>
                {/* image icon */}
                <div>
                    <img src={iconReferral} className='flex w-[25px]' alt="" />
                </div>

                <div className='font-normal text-[16px] text-white'>
                    <h3>REFERRALS</h3>
                </div>
            </div>
            {/* end of topic section */}

            <div className='w-full p-3 border border-white/20 mt-10 rounded-lg'>
                {/* referrals section */}
                <div className='flex w-full pb-4 gap-2'>
                    {/* referrals link section */}
                    <div className='w-[200px] px-4 py-2 bg-white/10 '>
                        <h3 className='text-white text-[14px]'>Your Referral Link </h3>
                    </div>

                    <div className='w-full px-4 py-2 bg-bethel-green/10  border-l border-white/80 flex gap-2 items-center'>
                        <input disabled className='text-white text-[12px] bg-transparent w-full p-1' defaultValue={refLink} ref={inputRef} />
                        <button onClick={handleCopy} className={`${copied ? "bg-bethel-newgreen" : "bg-green-700"} p-1 rounded-full `}>
                            <img src={copyIcon} className='w-[15px] ' alt="" />
                        </button>
                    </div>
                </div>

                {/* your referal counts */}
                <div className='py-2 bg-white/10 w-full px-2 flex justify-between items-center'>
                    <div className='w-full'>
                        <h3 className='text-white font-bold text-[1rem]'>Your Referral Count </h3>
                    </div>

                    {/* referral count */}
                    <div className=''>
                        <div className='p-2 rounded-full bg-[#0F0F0F] w-9 h-9 flex items-center justify-center'>
                            <h3 className='text-bethel-green font-bold '>{refCount && (refCount.RefCount ? refCount.RefCount : 0) }</h3>
                        </div>

                    </div>

                </div>
            </div>


        </div>
    )
}

export default ReferralsComponent