import { createAction, createSlice } from "@reduxjs/toolkit";
export const revertTriger = createAction('REVERT_ALL')

const initialState = {
    trigger : false
}

export const uploadTriggerSlice = createSlice({
    name : "SignupCounter",
    initialState,
    extraReducers : (builder) => builder.addCase(revertTriger, () => initialState),
    reducers : {
        saveTriger:(state,action) => action.payload
    }
})

export default uploadTriggerSlice;