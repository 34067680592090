import React from 'react'
import bethelIcon from "../../Images/icons/bethel-logo.png"

function PopUpCardForChoose({ Profile ,onBtnClick, onBtnClick2 }) {
    return (
        <div className='flex flex-col w-[400px] lg:w-[400px] md:w-[400px] sm:w-[400px] min-[320px]:w-[280px] h-auto absolute  bg-black/80 z-[1000] rounded-lg p-5 top-[300px] border-bethel-green border-[1px]
        animate__animated animate__zoomIn animate__faster'>
            {/* bethel image and content */}
            <div className='flex w-full justify-center gap-2 items-center'>
                <img src={bethelIcon} className='w-[30px] h-[30px] ' alt="" />
                <h3 className='text-white font-bold text-[16px]'>BETHEL PLATFORM</h3>
            </div>

            <hr className='text-white bg-white w-full mt-2 opacity-40' />

            {/* messesge content */}
            <div className='border-[1px] border-white/20 mt-4 p-2 rounded-lg'>
                <h1 className='text-red-600 text-[18px] text-center tracking-wider'>WARNING !</h1>
                <div className='flex flex-col '>
                    <h2 className='text-white text-[14px] py-2 text-center font-bold'>You choose {Profile} Profile</h2>
                    <p className='text-white text-[14px] text-center tracking-widest'>
                        Once you click 'Accept', you will not be able to change the profile later. Do you wish to proceed?
                    </p>
                </div>

            </div>


            {/* button component */}
            <div className='flex w-full justify-end py-2 gap-2'>
                <button onClick={onBtnClick} className='hover:bg-red-500 p-2 rounded-lg text-white font-bold text-[10px] w-[80px] border-[1px] border-white/40'>Deny</button>
                <button onClick={onBtnClick2} className='hover:bg-bethel-green/50 p-2 rounded-lg text-white font-bold text-[10px] w-[80px] border-[1px] border-white/40'>Accept</button>
            </div>
        </div>
    )
}

export default PopUpCardForChoose