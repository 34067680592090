import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertUpload = createAction('REVERT_ALL')

const initialState = {
    isUploading : false,
    isCompletet : false,
    isFail : false,
    barWidth : "w-[0%]",
    msg : ""
}

 const uploadStatusSlice = createSlice({
    name : "upload Status",
    initialState,
    extraReducers: (builder) => builder.addCase(revertUpload, () => initialState),
    reducers: {
    updateUpload: (state, action) => action.payload,
  }
})

export default uploadStatusSlice;