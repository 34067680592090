import React, { useState } from 'react'
import customImage from "../../Images/BG-Images/saterlight.png"
import cssWave from "../../Images/CssShape/wave.png"
import loadingGif from "../../Images/Animation-gifs/Rolling-1s-200px(1).gif"
import doneGif from "../../Images/icons/icon-newdone.png"

function CustomEnterprisePlan({ wallet_address, did, user_verify }) {
    const [userMsg, setUserMsg] = useState("") // handle usage msg
    const [processs, setProcesss] = useState({ is_verifying: false, is_complete: false, fail: false })
    const [verifyError, setVerifyError] = useState({ verify: false, msg: "Please Verify your mobile and Email in PROFILE" })

    const submitUserMsg = async () => {
        if (user_verify.email.is_verified && user_verify.mobile.phone_number) {
            setProcesss((prev) => ({ ...prev, is_verifying: true }))
            try {
                const res = await fetch(process.env.REACT_APP_BACKEND_URL + `email-send-for-plan`, {
                    method: "POST",
                    body: JSON.stringify({
                        // "to_addr": "no-reply@futurecx.com.au",
                        // "subject": "Enterprise Plan Request",
                        "body": userMsg,
                        "Email" : user_verify.mobile.phone_number,
                        "PhoneNumber" : user_verify.email.user_email 
                    })
                })

                if (res.status === 200) {
                    setProcesss((prev) => ({ ...prev, is_verifying: false, is_complete: true }))
                    setTimeout(() => {
                        setProcesss((prev) => ({ ...prev, is_verifying: false, is_complete: false }))
                    }, 3000);

                    setUserMsg('')
                }
                else {
                    setProcesss((prev) => ({ ...prev, is_verifying: false, is_complete: false, fail: true }))
                    setTimeout(() => {
                        setProcesss((prev) => ({ ...prev, is_verifying: false, is_complete: false, fail: false }))
                    }, 3000);
                    setUserMsg('')

                }
            } catch (error) {
                console.log(error)
                setProcesss((prev) => ({ ...prev, is_verifying: false, is_complete: false, fail: true }))
                setTimeout(() => {
                    setProcesss((prev) => ({ ...prev, is_verifying: false, is_complete: false, fail: false }))

                }, 3000);
                setUserMsg('')

            }
        }
        else {
            setVerifyError((prev) => ({ ...prev, verify: true, msg: "Please Verify your mobile and Email in PROFILE" }))
            setTimeout(() => {
                setVerifyError((prev) => ({ ...prev, verify: false, msg: "" }))

            }, 4000);
        }


    }
    return (
        <div className='flex w-full px-2 mb-4 overflow-hidden bg-black'>
            {/* error msg for submit */}
            { verifyError.verify && 
                <div className='fixed bottom-2 right-2 w-auto rounded-lg  border px-4 py-2 border-red-500 text-white bg-red-600 z-[999] animate__animated animate__fadeIn'>
                    <h3 className='font-semibold'>{verifyError.msg}</h3>
                </div>
            }
            {/* end of error msg */}


            <div className='flex border-[1px] border-white rounded-lg w-full p-4  overflow-hidden relative
                            lg:flex-row md:flex-row sm:flex-col min-[320px]:flex-col'>
                {/* image div */}
                <div className='flex flex-col justify-center items-center px-4 lg:border-r-[1px] border-white/40 '>
                    <img src={customImage} className='flex opacity-60 w-[200px] scale-[1.1]' alt="" />
                    <h3 className='text-white font-bold text-[2rem] tracking-widest text-center'>CORPORATE PLAN</h3>
                </div>

                <div className='w-full absolute top-0 left-0 z-[0]'>
                    <img src={cssWave} className='w-full opacity-70' alt="" />
                </div>

                {/* content divs */}
                <div className='flex flex-col relative z-[1]'>
                    <div className='flex p-4 flex-col'>
                        <h3 className='text-white/80 text-[1rem] font-bold tracking-wide'>Designed for large corporates and institutions that require seamless access to data on the go, this plan ensures that your files are secured and synced across all your devices, providing both convenience and security.</h3>
                        <li className='text-white/50 text-[0.9rem] font-bold px-4 py-3'>If your current storage isn't quite enough, we're here to help. Would you like to upgrade to a larger plan that offers even more space and enhanced features? Let us know</li>
                    </div>

                    <div className='p-4 w-full flex flex-col gap-2'>
                        <label htmlFor="" className='text-white font-bold text-[14px]'>Share Your Secure Storage Requirement</label>
                        <textarea value={userMsg} onChange={(e) => setUserMsg(e.target.value)} rows={4} className='w-full p-2 border-[1px] bg-transparent rounded-lg placeholder:text-white/50 text-[15px] text-white tracking-widest' placeholder='Type your request clearly here...' />
                    </div>

                    <div className='w-full justify-end flex px-4'>
                        <button onClick={submitUserMsg} disabled={processs.is_verifying} className={`${processs.is_complete ? "bg-green-500" : ""} px-4 py-2 border-white border-[1px] rounded-lg flex text-white hover:bg-bethel-green w-[150px] justify-center animate__animated animate__fadeIn transition-all ease-in-out`}>
                            { processs.is_verifying && <img src={loadingGif} className='w-[25px]' alt="" /> }
                            { !processs.is_verifying && !processs.is_complete &&  <h3 className='font-semibold '>SUBMIT</h3> }
                            { processs.is_complete && <img src={doneGif} className='w-[25px]' alt="" /> }
                        </button>
                    </div>

                </div>


            </div>



        </div>
    )
}

export default CustomEnterprisePlan