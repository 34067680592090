import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertPlan = createAction('REVERT_ALL')

const initialState = {
  planActive: false,
  planExpire : false,
  planSize : false
}

export const planStatusSlice = createSlice({
  name: "planStatusSlice",
  initialState,
  extraReducers: (builder) => builder.addCase(revertPlan, () => initialState),
  reducers: {
    saveStatus: (state, action) => action.payload
  }
})

export default planStatusSlice;