import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function NetworkCard({ card_image, card_heading, Card_subheading }) {

    const userDid = useSelector((state) => state.DidReducer)
    const [networkUsage, setNetworkUsage] = useState(null)

    const [days, setDays] = useState({
        day1: null,
        day2: null,
        day3: null,
        day4: null,
        day5: null,
        day6: null,
        day7: null,
        data1: null,
        data2: null,
        data3: null,
        data4: null,
        data5: null,
        data6: null,
        data7: null,
    })

    useEffect(() => {
        getNetworkUsage()
    }, [])

    //get network useage
    const getNetworkUsage = async () => {

        try {
            const networkUsageResponse = await fetch(process.env.REACT_APP_BACKEND_URL + `network-usage?OwnerDid=${userDid}`)
            const netUsageData = await networkUsageResponse.json()
            setNetworkUsage(netUsageData)

        // Set days 
        setDays((prev) => ({
            ...prev,
            day1: netUsageData[0].day && netUsageData[0].day.slice(5, 10),
            day2: netUsageData[0].day && netUsageData[1].day.slice(5, 10),
            day3: netUsageData[0].day && netUsageData[2].day.slice(5, 10),
            day4: netUsageData[0].day && netUsageData[3].day.slice(5, 10),
            day5: netUsageData[0].day && netUsageData[4].day.slice(5, 10),
            day6: netUsageData[0].day && netUsageData[5].day.slice(5, 10),
            day7: netUsageData[0].day && netUsageData[6].day.slice(5, 10),
            data1: netUsageData[0].day && netUsageData[0].daily_usage / 1000000,
            data2: netUsageData[0].day && netUsageData[1].daily_usage / 1000000,
            data3: netUsageData[0].day && netUsageData[2].daily_usage / 1000000,
            data4: netUsageData[0].day && netUsageData[3].daily_usage / 1000000,
            data5: netUsageData[0].day && netUsageData[4].daily_usage / 1000000,
            data6: netUsageData[0].day && netUsageData[5].daily_usage / 1000000,
            data7: netUsageData[0].day && netUsageData[6].daily_usage / 1000000,
        }))

        } catch (error) {
            console.log(error)
        }


    }
    const data = [
        {
            name: days ? days.day7 : "...",
            MiB: days ? days.data7 : 0,
            pv: 2400,
            amt: 2400,
        },
        {
            name: days ? days.day6 : "...",
            MiB: days ? days.data6 : 0,
            pv: 1398,
            amt: 2210,
        },
        {
            name: days ? days.day5 : "...",
            MiB: days ? days.data5 : 0,
            pv: 9800,
            amt: 2290,
        },
        {
            name: days ? days.day4 : "...",
            MiB: days ? days.data4 : 0,
            pv: 3908,
            amt: 2000,
        },
        {
            name: days ? days.day3 : "...",
            MiB: days ? days.data3 : 0,
            pv: 4800,
            amt: 2181,
        },
        {
            name: days ? days.day2 : "...",
            MiB: days ? days.data2 : 0,
            pv: 3800,
            amt: 2500,
        },
        {
            name: days ? days.day1 : "...",
            MiB: days ? days.data1 : 0,
            pv: 4300,
            amt: 2100,
        },
    ];

    return (
        <div className='relative flex flex-col w-full bg-black border-bethel-green/50 border-[1px] p-2 rounded-lg h-auto'>
            {/* container image */}
            <div className='flex absolute p-2 bg-bethel-green rounded-full top-[-15px] left-[-1%]'>
                <img src={card_image} className='w-[20px]' alt="" />
            </div>

            {/* data section */}
            <div className='flex flex-col w-full p-2 mt-3'>
                <h3 className='text-[18px]'>{card_heading}</h3>
                <h3 className='text-[14px] text-white/50'>{Card_subheading}</h3>
            </div>

            {/* line chart */}
            <div className='w-full h-full flex flex-col border-[1px] border-[white]/10 rounded-lg p-1 mt-1'>
                <ResponsiveContainer width="99%" aspect={4}>
                    <AreaChart
                        width={500}
                        height={400}
                        data={data}
                        margin={{
                            top: 10,
                            right: 25,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="1 1" />
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#a3d902" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#a3d902" stopOpacity={0} />
                            </linearGradient>
                        </defs>

                        <XAxis dataKey="name" textDecoration={2} />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="MiB" stroke="#a3d902" fillOpacity={1} fill="url(#colorUv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </div>


        </div>
    )
}

export default NetworkCard