import { createAction, createSlice } from "@reduxjs/toolkit";
export const revertTotalPoints = createAction('REVERT_ALL')

const initialState = {
    totalPoints : 0
}

const totalPointsSlice = createSlice({
    name : "totalPoints",
    initialState,
    extraReducers : (builder) => builder.addCase(revertTotalPoints, () => initialState),
    reducers : {
        saveTotalPoints : (state,action) => action.payload
    }
})

export default totalPointsSlice;