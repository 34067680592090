import React from 'react'

function FilesCards({ Count, Icon, Name, Size}) {
    return (
        <div>
            {/* content section */}
            <div className='flex w-full mt-2'>
                {/* files div */}
                <div className='flex w-full p-2 gap-2 justify-between items-center bg-white/10 rounded-lg'>
                    {/* image */}
                    <div className='w-full flex items-center justify-start gap-2'>
                        <div className=''>
                            <img src={Icon} alt="" className='w-[20px] h-auto' />
                        </div>
                        
                        <div className='flex flex-col'>
                           <h3 className='text-white text-[16px]'>{Name}</h3>
                        <h3 className='text-[10px] text-white/50'>{Count} files</h3>  
                        </div>
                        
                    </div>

                    {/* count */}
                    <div className='flex w-full justify-end'>
                        <h3 className='text-[14px] text-white/50'>{Size} MiB</h3>
                    </div>
                </div>

            </div></div>
    )
}

export default FilesCards