import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, isAnimationActive } from 'recharts';
import FilesCards from './MiniCards/FilesCards';
import fileIcon from "../../Images/icons/icon-file.png"
import iconImages from "../../Images/icons/icons-images-96.png"
import iconAudio from "../../Images/icons/icons8-music-100.png"
import iconVideo from "../../Images/icons/icon-video.png"


function PieChartCard({ card_icon, card_heading, card_subHeading, UsedValue, FilesCount, currentPackage }) {

    const [value, setValue] = useState(5)

    const getCurrentGB = () => {

        switch (currentPackage) {
            case "Basic":
                setValue(5);
                break;
            case "Advance":
                setValue(5000);
                break;
            case "Starter":
                setValue(1000)
                break;
            default:
                setValue(5)
        }
    }

    useEffect(()=>{
        getCurrentGB()
    },[currentPackage ])


    const data01 = [
        { name: 'Used (GiB)', value: UsedValue /1000 , fill: '#2cffae' },
        { name: 'Remain (GiB)', value: value - UsedValue /1000 ,fill: '#a3d902' },
    ];

    return (
        <div className='relative flex flex-col bg-black border-bethel-green/50 border-[1px] p-2 rounded-lg h-auto
    lg:w-full md:w-full sm:w-full min-[320px]:w-full'>
            {/* card image div */}
            {/* file icon */}
            <div className='flex absolute p-2 bg-bethel-green rounded-full top-[-15px] left-[-1%]'>
                <img src={card_icon} className='w-[20px]' alt="" />
            </div>

            {/* heading */}
            {/* heading and subheading */}
            <div className='w-full p-2 mt-3'>
                <h3 className='text-[18px]'>{card_heading}</h3>
                <h4 className='text-[14px] text-white/50'>{card_subHeading}</h4>
            </div>

            {/* pie chart div */}

            {/* chart section */}
            <div className='w-full lg:h-[250px] min-[3200px]:h-[300px] md:h-[300px] sm:h-[300px] min-[320px]:h-[300px] flex flex-col justify-center items-center mt-9 p-2 border-[1px] border-white/20 rounded-lg'>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={true}
                            data={data01}
                            outerRadius={70}
                            label
                        />
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>

            </div>


            {/* details section */}
            <div className='flex flex-col gap-2 w-full h-auto p-2 mt-4'>
                {/* heading in details section */}
                <div className='w-full'>
                    <h3 className='text-white/50 '>Details :</h3>
                </div>
                <FilesCards Icon={fileIcon} Name={"Files"} Count={FilesCount} Size={UsedValue} />
                <FilesCards Icon={iconAudio} Name={"Audio"} Count={0} Size={0} />
                <FilesCards Icon={iconVideo} Name={"Videos"} Count={0} Size={0} />
                <FilesCards Icon={iconImages} Name={"Images"} Count={0} Size={0} />
            </div>
        </div>
    )
}

export default PieChartCard