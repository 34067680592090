import React from 'react'
import warning from "../../Images/icons/icon-warning.png"


function LoginValidationCheck({authAddress ,onBtnClick}) {
  return (
    <div className='w-[700px] h-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate__animated animate__fadeIn animate__fast
    bg-black border-[1px] border-bethel-green p-12 flex-col justify-center items-center z-[99999] rounded-lg'>
       {/* topic section */}
       <div className='flex w-full justify-center items-center p-1 gap-2'>
           <img src={warning} className='w-[40px]' alt="" />
           <h3 className='text-white text-[25px] font-bold'><span className='text-yellow-500'>Warning:Wallet Address wasn't Register !</span></h3>
       </div>

       <hr className='w-[95%] opacity-50 mt-1 mb-1' />

       {/* content  */}
       <div className='flex w-full flex-col py-3 text-center gap-2 p-2'>
           <div className='text-white text-[16px] tracking-wider'>It appears that current connected wallet addresses does not match with login DID. Please Change the wallet address into one of them from below or Go to Register User.</div>

           <div className='text-[14px] text-white flex flex-col gap-2 mt-3'><span className='font-bold'>Linked Wallet addresses:</span>
               { authAddress && authAddress.map((address ,index) => {
                   return <h3 className='text-yellow-500 text-[14px]'><span className='text-white'>{index + 1}.</span> {address}</h3>
               })}
           </div>
       </div>

       {/* close button */}
       <button onClick={onBtnClick} className='absolute bottom-2 right-2 bg-transparent border-white/50 border-[1px] px-3 py-1 rounded-lg text-white text-[12px] hover:bg-red-500'>
           Close
       </button>
   </div>
  )
}

export default LoginValidationCheck