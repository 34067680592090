import React from 'react'

function SamllCard({card_icon, heading, subheading, card_data}) {
  return (
    <div className='relative flex bg-black border-bethel-green/50 border-[1px] p-2 rounded-lg h-auto
    lg:w-full md:w-full sm:w-full min-[320px]:w-full min-[2500px]:w-full'>
        {/* file icon */}
        <div className='flex absolute p-2 bg-bethel-green/100 rounded-full top-[-15px] left-[-1%]'>
            <img src={card_icon} className='w-[20px]' alt="" />
        </div>

        {/* heading and subheading */}
        <div className='w-full p-2 mt-3'>
            <h3 className='text-[18px]'>{heading}</h3>
            <h4 className='text-[14px] text-white/50'>{subheading} {card_data}</h4>
        </div>
    </div>
  )
}

export default SamllCard