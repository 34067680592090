import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertAll4 = createAction('REVERT_ALL')

const initialState = {
    current_account : null
}

 const currentAccount = createSlice({
    name : "Verifier_did",
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll4, () => initialState),
    reducers: {
    saveCurrentAccount: (state, action) => action.payload,
  }
})

export default currentAccount;