import React from 'react'
import metamask from "../../Images/icons/metamask.png"
import metamaskImage from "../../Images/MetamaskImg/1.png"
import metamaskImage2 from "../../Images/MetamaskImg/2.png"
import metamaskImage3 from "../../Images/MetamaskImg/3.png"
import metamaskImage4 from "../../Images/MetamaskImg/4.png"
import metamaskImage5 from "../../Images/MetamaskImg/5.png"
import metamaskImage6 from "../../Images/MetamaskImg/6.png"
import metamaskImage7 from "../../Images/MetamaskImg/7.png"
import metamaskImage8 from "../../Images/MetamaskImg/8.png"
import metamaskImage9 from "../../Images/MetamaskImg/9.png"
import metamaskImage10 from "../../Images/MetamaskImg/10.png"
import metamaskImage11 from "../../Images/MetamaskImg/11.png"
import metamaskImage12 from "../../Images/MetamaskImg/12.png"
import metamaskImage13 from "../../Images/MetamaskImg/13.png"
import metamaskImage14 from "../../Images/MetamaskImg/14.png"
import { Carousel } from 'react-responsive-carousel';
import iconClose from "../../Images/icons/icons8-close-30.png"
import "react-responsive-carousel/lib/styles/carousel.min.css";



function MetamaskInstalationGuide({colseBtn}) {

    return (
        <div className='fixed w-[500px] h-auto bg-black border border-white/70 bottom-9 right-0 p-2 flex flex-col items-center rounded-lg animate__animated animate__fadeInUp'>
            {/* close button */}
            <button onClick={colseBtn} className='p-2 border border-red-600 rounded-full absolute top-2 right-2'>
                <img src={iconClose} className='w-[10px]' alt="" />
            </button>


            {/* topic */}
            <div className='w-full justify-center flex items-center gap-2'>
                <div className='p-2 rounded-full bg-black border border-orange-700'>
                    <img src={metamask} className='w-[25px]' alt="" />
                </div>
                <h2 className='text-[14px] text-white'>Meta Mask Installation Guide</h2>
            </div>

            <hr className='w-[90%] opacity-50 mt-2' />

            {/* content div */}
            <div className='text-white flex pt-4 border border-white/50 rounded-lg p-2'>
                <Carousel className='px-4 py-2'>
                    <div>
                        <img src={metamaskImage} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage2} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage3} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage4} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage5} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage6} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage7} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage8} className='w-[100px] h-[300px]' alt="" />
                    </div>

                    <div>
                        <img src={metamaskImage9} className='w-[100px] h-[300px]' alt="" />
                    </div>
                    <div>
                        <img src={metamaskImage10} className='w-[100px] h-[300px]' alt="" />
                    </div>
                    <div>
                        <img src={metamaskImage11} className='w-[100px] h-[300px]' alt="" />
                    </div>
                    <div>
                        <img src={metamaskImage12} className='w-[100px] h-[300px]' alt="" />
                    </div>
                    <div>
                        <img src={metamaskImage13} className='w-[100px] h-[300px]' alt="" />
                    </div>
                    <div>
                        <img src={metamaskImage14} className='w-[100px] h-[300px]' alt="" />
                    </div>
                </Carousel>
            </div>

        </div>
    )
}

export default MetamaskInstalationGuide