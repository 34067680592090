import React from 'react'
import metamaskIcon from "../../Images/icons/metamask.png"

function InstallMetamaskComp({onBtnClick}) {
    return (
        <div className='w-[700px] h-[350px] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 animate__animated animate__fadeIn animate__fast
         bg-black border-[1px] border-bethel-green p-8 flex-col justify-center items-center z-[99999] rounded-lg'>
            {/* topic section */}
            <div className='flex w-full justify-center items-center p-1 gap-2'>
                <img src={metamaskIcon} className='w-[40px]' alt="" />
                <h3 className='text-white text-[28px] font-bold'><span className='text-[#F6851B]'>Meta Mask</span> is Not Detected</h3>
            </div>

            <hr className='w-[95%] opacity-50 mt-1 mb-1' />

            {/* content  */}
            <div className='flex w-full flex-col py-3 text-center gap-2 p-2'>
                <div className='text-white text-[16px] tracking-wider'>It appears that MetaMask is not installed or enabled in your browser. MetaMask is required to interact with the decentralized features of this application.</div>

                <div className='text-[12px] text-white flex flex-col gap-2'><span className='font-bold'>To continue:</span>
                    <span className='text-white/70'>1. Install MetaMask: Visit the official <a href='https://metamask.io/download/' target='_blank' rel="noreferrer" className='text-[#F6851B]'><u>MetaMask website</u></a> and download the extension for your browser.</span>
                    <span className='text-white/70'>2. Enable MetaMask: If you have MetaMask installed but it's not detected, please ensure that it is enabled and connected.
                        Once MetaMask is installed and active, refresh the page to proceed.
                    </span>
                </div>
            </div>

            {/* close button */}
            <button onClick={onBtnClick} className='absolute bottom-2 right-2 bg-transparent border-white/50 border-[1px] px-3 py-1 rounded-lg text-white text-[12px] hover:bg-red-500'>
                Close
            </button>
        </div>
    )
}

export default InstallMetamaskComp