import { createAction, createSlice } from "@reduxjs/toolkit";

export const revertAll4 = createAction('REVERT_ALL')

const initialState = {
    verifier_did : '',
    current_account : null
}

 const verifierReducer = createSlice({
    name : "Verifier_did",
    initialState,
    extraReducers: (builder) => builder.addCase(revertAll4, () => initialState),
    reducers: {
    saveVerifierDid: (state, action) => action.payload,
  }
})

export default verifierReducer;