import React, { useState } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import iconHome from '../Images/icons/icon-home.png'
import iconDolar from "../Images/icons/icons8-dolar-64.png"
import iconHelp from "../Images/icons/icon-help.png"
import iconUser from "../Images/icons/icon-male-user.png"
import iconWallet from "../Images/icons/icon-wallet.png"
import iconStorage from "../Images/icons/icon-storage.png"
import iconBethel from "../Images/icons/bethelIcon.png"
import iconLogoout from "../Images/icons/icon-logout.png"
import { useDispatch, useSelector } from 'react-redux'
import { revertAll } from '../reducers/Loginreducer'
import { revertAll2 } from '../reducers/storageDetailsSlice'
import { revertAll3 } from '../reducers/uploadDetailsSlice'
import { revertAll4 } from '../reducers/userDataReducer'
import toggleSidebarSlice from '../reducers/toggleSidebar'
import iconClose from '../Images/icons/icons8-close-96.png'
import fileIcon from "../Images/icons/icon-file.png"
import { revertAll8 } from '../reducers/didRedcuer'
import { revertTransactionHash } from '../reducers/TransactionHashReducer'
import FileCompBlurSlice from '../reducers/filesCompBlurReducer'
import { revertBecx } from '../reducers/becxReduser'
import iconReferral from "../Images/icons/icon-referrals.png"


function SideBar() {
  const [toggleFile, setToggleFile] = useState(false)
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

  const toggle = () => {
    dispatch(toggleSidebarSlice.actions.toggleSidebar())
    dispatch(FileCompBlurSlice.actions.changeBlur(null))

  }

  const toggleFiles = () => {
    setToggleFile(!toggleFile)
    dispatch(FileCompBlurSlice.actions.changeBlur(null))
  }

  const logOut = () => {
    Navigate('/')
    dispatch(revertAll(), revertAll4(), revertAll2(), revertAll3(), revertAll8(), revertTransactionHash(), revertBecx())
  }

  const toggleSidebar = useSelector((state) => state.toggleSidebarReducer)

  return (
    // side nav bar for mobile
    <div>
      {!toggleSidebar.toggleSidebar &&
        <div className=' fixed lg:hidden md:flex sm:fixed left-0 rounded-md w-[250px] backdrop-blur-xl bg-gradient-to-b from-bethel-white/5 to-gray-800/10 top-2 bottom-0 p-2 animate__animated animate__fadeInLeft animate__faster'>
          {/* inside contenet */}
          <div className='relative flex flex-col items-baseline justify-start h-full'>
            {/* inside flex boxes */}

            {/* CLOSE BUTTON */}
            <div onClick={toggle} className='absolute top-0 right-1'>
              <img src={iconClose} alt="" className='w-[20px]' />
            </div>

            {/* topic */}
            <div className='w-full flex flex-col justify-center items-center text-[1.4rem] mt-5'>
              <h1 className='font-bold bg-gradient-to-r from-bethel-orange to-bethel-newgreen text-transparent bg-clip-text outline-4 outline-white'><img src={iconBethel} alt="" className='w-[30px] absolute left-4 top-[20px]' /><span className='text-white'><span className='text-bethel-green'>zk</span>STORAGE</span></h1>
              <hr className='w-[90%] mt-1 opacity-20' />
            </div>
            {/* end-topic */}

            <div className='flex flex-col items-start justify-center gap-4 mt-6 w-full text-[1rem] text-white'>

              {/* start-dashboard */}
              <Link to='/dashboard' className='w-full' onClick={toggle}>

                <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-center justify-center mt-10 xs:flex-col xxs:flex-col xxxs:flex-col">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    {/* <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div> */}
                    <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <div className='flex w-full justify-start ml-14'>
                            {/* start-inside box */}
                            <div className='flex w-full gap-2'>
                              <div>
                                <img src={iconHome} alt="" className='w-[20px]' />
                              </div>
                              <h1>DASHBOARD</h1>
                            </div>
                            {/* end-insidebox */}
                          </div>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div>
                </button>

              </Link>
              {/* end-dashboard */}

              {/* start-storage */}
              {/* <Link to='/dashboard/files' className='w-full'> */}
              <Link to="/dashboard/files" className='w-full' onClick={toggle}>

                <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-center justify-start w-full xs:flex-col xxs:flex-col xxxs:flex-col">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                    <div className="relative w-56 h-12 mr-4  bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <div className='flex w-full justify-start ml-14'>
                            {/* start-inside box */}
                            <div className='flex w-full gap-2'>
                              <div>
                                <img src={fileIcon} alt="" className='w-[20px]' />
                              </div>
                              <h1>FILES</h1>
                            </div>
                            {/* end-insidebox */}
                          </div>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div>
                </button>

              </Link>
              {/* end-dashboard */}

              {/* start-dashboard */}
              <Link to='/dashboard/profile' className='w-full' onClick={toggle}>
                <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                    <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <div className='flex w-full justify-start ml-14'>
                            {/* start-inside box */}
                            <div className='flex w-full gap-2'>
                              <div>
                                <img src={iconUser} alt="" className='w-[20px]' />
                              </div>
                              <h1>PROFILE</h1>
                            </div>
                            {/* end-insidebox */}
                          </div>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div>
                </button>
              </Link>
              {/* end-dashboard */}

              {/* start-dashboard */}
              <Link to='/dashboard/billing' className='w-full' onClick={toggle}>
                <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                    <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <div className='flex w-full justify-start ml-14'>
                            {/* start-inside box */}
                            <div className='flex w-full gap-2'>
                              <div>
                                <img src={iconDolar} alt="" className='w-[20px]' />
                              </div>
                              <h1>PLANS</h1>
                            </div>
                            {/* end-insidebox */}
                          </div>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div>
                </button>
              </Link>
              {/* end-dashboard */}

              {/* start-dashboard */}
              <Link to='/dashboard/activities' className='w-full' onClick={toggle}>
                <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                    <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                        <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <div className='flex w-full justify-start ml-14'>
                            {/* start-inside box */}
                            <div className='flex w-full gap-2'>
                              <div>
                                <img src={iconDolar} alt="" className='w-[20px]' />
                              </div>
                              <h1>ACTIVITIES</h1>
                            </div>
                            {/* end-insidebox */}
                          </div>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div>
                </button>
              </Link>
              {/* end-dashboard */}

              <Link to='/dashboard/referrals' className='w-full' onClick={toggle}>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser  ? "" : "pointer-events-none grayscale"} flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconReferral} alt="" className='w-[20px]' />
                            </div>
                            <h1>Referrals</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>
            </Link>

            </div>

            {/* START-HELP AND LOGOUT FUNC */}
            <div className='absolute bottom-0 flex flex-col gap-y-4 justify-center w-[95%] text-[1rem] text-white'>
              {/* start-dashboard */}
              <div className='flex w-full px-10 py-2'>
                <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                  <a href="https://docs.bethelnet.io/" target='_blank' rel="noreferrer">
                    <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                      <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                      <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                        <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-black rounded-md">
                          <span className="flex items-center justify-center font-bold  h-11 xxxs:h-7 hover:text-black font-raleway ">
                            <div className='flex w-full justify-start ml-14'>
                              {/* start-inside box */}
                              <div className='flex w-full gap-2'>
                                <div>
                                  <img src={iconHelp} alt="" className='w-[20px]' />
                                </div>
                                <h1>HELP</h1>
                              </div>
                              {/* end-insidebox */}
                            </div>
                          </span>
                        </div>
                      </div>
                      {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                    </div></a>
                </button>
              </div>
              {/* end-dashboard */}


              {/* start-dashboard */}
              <button onClick={logOut} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconLogoout} alt="" className='w-[20px]' />
                            </div>
                            <h1>LOGOUT</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>
              {/* end-dashboard */}
            </div>
            {/* END-HELP AND LOGOUT */}



          </div>
          {/* end of inside content */}
        </div>
      }

      {/* side bar for pc */}
      <div className='fixed lg:flex md:hidden sm:hidden min-[320px]:hidden left-2 rounded-md w-[250px] backdrop-blur-xl bg-gradient-to-b from-bethel-white/5 to-gray-800/10 top-2 bottom-2 p-2'>
        {/* inside contenet */}
        <div className='flex flex-col items-baseline justify-start h-full'>
          {/* inside flex boxes */}

          {/* topic */}
          <div className='w-full flex flex-col justify-center items-center text-[1.4rem] mt-3'>
            <h1 className='font-bold bg-gradient-to-r from-bethel-orange to-bethel-newgreen text-transparent bg-clip-text outline-4 outline-white ml-6'><img src={iconBethel} alt="" className='w-[30px] absolute left-9 top-[22px]' /><span className='text-white'><span className='text-bethel-green'>zkp</span>STORAGE</span></h1>
            <hr className='w-[90%] mt-4 opacity-20' />
          </div>
          {/* end-topic */}

          <div className='flex flex-col items-start justify-center gap-4 mt-6 w-full text-[1rem] text-white'>

            {/* start-dashboard */}
            <NavLink className="w-full" to='/dashboard'>

              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale"} flex items-center justify-center mt-10 xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  {/* <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div> */}
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconHome} alt="" className='w-[20px]' />
                            </div>
                            <h1>DASHBOARD</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>

            </NavLink>
            {/* end-dashboard */}

            {/* start-storage */}
            {/* <Link to='/dashboard/files' className='w-full'> */}
            <Link to="/dashboard/files" className='w-full'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale"} flex items-center justify-start w-full xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4  bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className="  w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={fileIcon} alt="" className='w-[20px]' />
                            </div>
                            <h1>FILES</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>

            </Link>

            {/* </Link> */}

            {/* start-dashboard */}
            <Link to='/dashboard/profile' className='w-full'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale"} flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconUser} alt="" className='w-[20px]' />
                            </div>
                            <h1>PROFILE</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>
            </Link>
            {/* end-dashboard */}

            {/* start-dashboard */}
            <Link to='/dashboard/billing' className='w-full'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale"} flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconDolar} alt="" className='w-[20px]' />
                            </div>
                            <h1>PLANS</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>
            </Link>
            {/* end-dashboard */}

            {/* start-dashboard */}
            <Link to='/dashboard/activities' className='w-full'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale"} flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconDolar} alt="" className='w-[20px]' />
                            </div>
                            <h1>ACTIVITIES</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>
            </Link>

            <Link to='/dashboard/referrals' className='w-full'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale"} flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                          {/* start-inside box */}
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconReferral} alt="" className='w-[20px]' />
                            </div>
                            <h1>Referrals</h1>
                          </div>
                          {/* end-insidebox */}
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                </div>
              </button>
            </Link>
            {/* end-dashboard */}

            {/* swap link */}
            {/* <Link to='/dashboard/swap' className='w-full'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className={`${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser  ? "" : "pointer-events-none grayscale"} flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col`}>
                <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                  <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                  <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                    <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-white rounded-md">
                      <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                        <div className='flex w-full justify-start ml-14'>
                   
                          <div className='flex w-full gap-2'>
                            <div>
                              <img src={iconDolar} alt="" className='w-[20px]' />
                            </div>
                            <h1>ATOMIC SWAP</h1>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            </Link> */}
            {/* end-dashboard */}

          </div>

          {/* START-HELP AND LOGOUT FUNC */}
          <div className='absolute bottom-0 flex flex-col gap-y-4 justify-center w-[95%] text-[1rem] text-white'>
            {/* start-dashboard */}
            <div className='flex w-full px-10 py-2'>
              <button onClick={() => dispatch(FileCompBlurSlice.actions.changeBlur(null))} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
                <a href="https://docs.bethelnet.io/" target='_blank' rel="noreferrer">
                  <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                    <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                    <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                      <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent  hover:text-black rounded-md">
                        <span className="flex items-center justify-center font-bold  h-11 xxxs:h-7 hover:text-black font-raleway ">
                          <div className='flex w-full justify-start ml-14'>
                            {/* start-inside box */}
                            <div className='flex w-full gap-2'>
                              <div>
                                <img src={iconHelp} alt="" className='w-[20px]' />
                              </div>
                              <h1>HELP</h1>
                            </div>
                            {/* end-insidebox */}
                          </div>
                        </span>
                      </div>
                    </div>
                    {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
                  </div></a>
              </button>
            </div>
            {/* end-dashboard */}


            {/* start-dashboard */}
            <button onClick={logOut} className="flex items-start w-full justify-center xs:flex-col xxs:flex-col xxxs:flex-col">
              <div className="flex duration-500 hover:-translate-y-1 delay-50 hover:text-white xs:mb-5 xxs:mb-6 xxxs:mb-5">
                <div className="triangle-container bg-bethel-orange w-4 h-3 -mr-4 xxxs:w-[10px] xxxs:h-2 xxxs:-mr-[10px]  "></div>
                <div className="relative w-56 h-12 mr-4 bg-gradient-to-r from-bethel-orange to-bethel-newgreen xxxs:w-36 xxxs:h-8 hover:text-white rounded-md">
                  <div className=" w-[220px] h-[44px] xxxs:w-[140px] xxxs:h-[28px] relative left-[2px] top-[2px] bg-black hover:bg-transparent hover:text-white rounded-md">
                    <span className="flex items-center justify-center font-bold text-white h-11 xxxs:h-7 hover:text-black font-raleway ">
                      <div className='flex w-full justify-start ml-14'>
                        {/* start-inside box */}
                        <div className='flex w-full gap-2'>
                          <div>
                            <img src={iconLogoout} alt="" className='w-[20px]' />
                          </div>
                          <h1>LOGOUT</h1>
                        </div>
                        {/* end-insidebox */}
                      </div>
                    </span>
                  </div>
                </div>
                {/* <div className="triangle-container1 bg-bethel-newgreen w-4 h-3 -ml-8 mt-9 xxxs:w-[10px] xxxs:h-2 xxxs:-ml-[26px] xxxs:mt-6 hover:bg-bethel-newgreen "></div> */}
              </div>
            </button>
            {/* end-dashboard */}
          </div>
          {/* END-HELP AND LOGOUT */}


        </div>
        {/* end of inside content */}
      </div>
    </div>

  )
}

export default SideBar
