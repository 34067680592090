import React, { useState } from 'react'
import cssWave from "../../Images/CssShape/wave.png"
import iconTick from "../../Images/icons/icon-tick.png"
import { useSelector } from 'react-redux'
import "../style.css"

function PricePlanCard({ duration, plan_perioad, plan_status2, plan_expire, plan_image, price, plan_name, plan_space, plan_description, onBtnClick, plan_status, currentPlan, plan_active }) {
    const planStatus = useSelector((state) => state.requireAvalibilityReducer)
    const [toggle, setToggle] = useState(false) //toggle price with becx
    const [toggleBECX, setToggleBECX] = useState(false)
    const becx_price = useSelector((state) => state.becxSliceReducer) // get becx price

    const mouseLeave = () => {
        setTimeout(() => {
            setToggleBECX(false)
        }, 500);
    }

    return (
        <div className={`${plan_active ? "pointer-events-none shadow-[0_0_15px_rgba(255,0,0,0.8)]" : ""} ${planStatus.noUser && plan_name !== "BASIC PLAN" ? "pointer-events-none grayscale" : ""}
        ${currentPlan && duration ? "shadow-[0_0_15px_rgba(0,255,0,0.8)] bg-black" : "bg-black "} flex-col w-full border-[1px] hover:border-bethel-green hover:border-animate-pulse h-auto rounded-lg scale-95 relative flex `}>

            {/* cannot activate div */}
            {plan_active && <div className='w-auto h-auto absolute top-3 left-3 rounded-lg p-3 border-[1px] border-red-500 '>
                <h3 className='text-red-500 text-[10px] font-bold'>ONE-TIME TRIAL ONE MONTH ONLY </h3>
            </div>}


            {/* plan image and place title */}
            <div className='flex flex-col w-full justify-center items-center'>
                <img src={plan_image} className='w-[200px] h-auto scale-100 opacity-70' alt="" />

                <div className='w-full text-center z-[20] relative'>
                    <h3 className='text-white font-bold text-[2rem] tracking-widest'>{plan_name}</h3>

                    {/* if have a discount price */}
                    {(plan_perioad.month3 || plan_perioad.month6 || plan_perioad.year) &&
                        <div className='w-full'>
                            {!toggleBECX &&
                                <div onMouseEnter={() => setToggleBECX(true)} onMouseLeave={mouseLeave} className=' bg-black flex flex-col w-auto px-16 py-2  rounded-lg font-bold mt-2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-28 z-[10]'>
                                    <h3 className='text-green-500 text-[25px] font-bold'>
                                        {plan_perioad.month3 && plan_name === "ADVANCE PLAN" && "$76.5"} {plan_perioad.month3 && plan_name === "STARTER PLAN" && "$25.5"}
                                        {plan_perioad.month6 && plan_name === "ADVANCE PLAN" && "$135"} {plan_perioad.month6 && plan_name === "STARTER PLAN" && "$45"}
                                        {plan_perioad.year && plan_name === "ADVANCE PLAN" && "$234"} {plan_perioad.year && plan_name === "STARTER PLAN" && "$78"}
                                    </h3>


                                    <h3 className='text-white/50'>
                                        {plan_perioad.month3 && plan_name === "ADVANCE PLAN" && "15% Off"} {plan_perioad.month3 && plan_name === "STARTER PLAN" && "15% Off"}
                                        {plan_perioad.month6 && plan_name === "ADVANCE PLAN" && "25% Off"} {plan_perioad.month6 && plan_name === "STARTER PLAN" && "25% Off"}
                                        {plan_perioad.year && plan_name === "ADVANCE PLAN" && "35% Off"} {plan_perioad.year && plan_name === "STARTER PLAN" && "35% Off"}
                                    </h3>
                                </div>
                            }

                            {/* becx price hover div */}
                            {toggleBECX &&
                                <div onMouseLeave={mouseLeave}  className=' bg-transparent flex flex-col h-auto px-16 py-2 w-fit  rounded-lg font-bold mt-2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-28 z-[10]'>
                                    <h3 className='text-white lg:text-[25px] md:text-[15px] sm:text-[20px] min-[320px]:text-[20px] font-bold animate__animated animate__flipInX'>
                                        {plan_perioad.month3 && plan_name === "ADVANCE PLAN" && `${becx_price && (76.5 / becx_price.price).toFixed()} becx`} {plan_perioad.month3 && plan_name === "STARTER PLAN" && `${becx_price && (25.5 / becx_price.price).toFixed()} becx`}
                                        {plan_perioad.month6 && plan_name === "ADVANCE PLAN" && `${becx_price && (135 / becx_price.price).toFixed()} becx`} {plan_perioad.month6 && plan_name === "STARTER PLAN" && `${becx_price && (45 / becx_price.price).toFixed()} becx`}
                                        {plan_perioad.year && plan_name === "ADVANCE PLAN" && `${becx_price && (234 / becx_price.price).toFixed()} becx`} {plan_perioad.year && plan_name === "STARTER PLAN" && `${becx_price && (78 / becx_price.price).toFixed()} becx`}
                                    </h3>


                                    <h3 className='text-white/50'>
                                        {plan_perioad.month3 && plan_name === "ADVANCE PLAN" && "15% Off"} {plan_perioad.month3 && plan_name === "STARTER PLAN" && "15% Off"}
                                        {plan_perioad.month6 && plan_name === "ADVANCE PLAN" && "25% Off"} {plan_perioad.month6 && plan_name === "STARTER PLAN" && "25% Off"}
                                        {plan_perioad.year && plan_name === "ADVANCE PLAN" && "35% Off"} {plan_perioad.year && plan_name === "STARTER PLAN" && "35% Off"}
                                    </h3>
                                </div>
                            }                           F
                            {/* end of becx price div */}

                            <div  className=' bg-green-500 flex flex-col h-auto px-16 py-4  rounded-lg font-bold mt-2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-28 z-[9] scale-[1.02] animate-pulse shadow-[0_0_15px_5px_rgba(34,197,94,0.8)]'>
                                <h3 className='text-green-500 text-[25px] font-bold'>$40</h3>
                                {/* <h3 className='text-white'>15% off</h3> */}
                                </div>
                        </div>}
                    {/* end of discount price */}


                </div>

                {/* svg div */}
                <div className='w-full relative'>
                    <img src={cssWave} className='w-full opacity-70' alt="" />
                </div>
            </div>

            {/* price tag rouded shape */}
            <div className='absolute top-[-1.2rem] right-[-1.2rem] w-[100px] h-[100px] rounded-full bg-bethel-green animate-pulse'></div>
            <div onMouseEnter={() => setToggle(true)} onMouseLeave={() => setToggle(false)} className='absolute top-[-0.9rem] right-[-0.9rem] w-[98px] h-[98px] rounded-full bg-black flex flex-col items-center justify-center'>
                {
                    plan_perioad.month &&
                    <div className='flex w-full items-center justify-center'>
                        <h3
                            className={`text-white ${toggle ? "hidden" : ""} text-[24px] tracking-wider font-semibold animate__animated animate__flipInY`}>${price}
                        </h3>

                        <div
                            className={`text-white flex flex-col justify-center items-center ${toggle ? "" : "hidden"} text-[24px] tracking-wider font-semibold animate__animated animate__flipInX`}>
                            <h3>{becx_price && (price / becx_price.price).toFixed(0)}</h3>
                            <h3 className='text-[12px] tracking-normal font-bold'>BECX</h3>
                        </div>
                    </div>
                }
                {
                    (plan_perioad.month3 || plan_perioad.month6 || plan_perioad.year) &&
                    <div className='flex w-full items-center justify-center'>
                        <h3
                            className={`text-red-500 ${toggle ? "hidden" : ""} text-[24px] tracking-wider font-semibold animate__animated animate__flipInY`}><del className='custom-line-through'>${price}</del>
                        </h3>

                        <div
                            className={`text-white flex flex-col justify-center items-center ${toggle ? "" : "hidden"} text-[24px] tracking-wider font-semibold animate__animated animate__flipInY`}>
                            <h3>{becx_price && (price / becx_price.price).toFixed(0)}</h3>
                            <h3 className='text-[12px] tracking-normal font-bold'>BECX</h3>
                        </div>
                    </div>


                }
            </div>

            {/* content in the plan */}
            <div className='w-full flex flex-col text-white px-5 pb-4'>
                {/* description  */}
                <div className='text-[0.9rem] font-bold text-white/80 text-center pb-3 -mt-4 h-[110px]'>
                    <h3>{plan_description}</h3>
                </div>

                <div className='w-full px-3 text-white/50 gap-3 tracking-wider'>
                    <div className='flex w-full gap-2 items-center'>
                        <img src={iconTick} className='w-[20px] opacity-50' alt="" />
                        <h3 className='text-[0.9rem]'>Unlimited Uploads</h3>
                    </div>
                    <div className='flex w-full gap-2 items-center'>
                        <img src={iconTick} className='w-[20px] opacity-50' alt="" />
                        <h3 className='text-[0.9rem]'>Upto {plan_space} storage space</h3>
                    </div>
                    <div className='flex w-full gap-2 items-center'>
                        <img src={iconTick} className='w-[20px] opacity-50' alt="" />
                        <h3 className='text-[0.9rem]'>Hack-Proof</h3>
                    </div>
                    <div className='flex w-full gap-2 items-center'>
                        <img src={iconTick} className='w-[20px] opacity-50' alt="" />
                        <h3 className='text-[0.9rem]'>ZKP Protected</h3>
                    </div>
                    <div className='flex w-full gap-2 items-center'>
                        <img src={iconTick} className='w-[20px] opacity-50' alt="" />
                        <h3 className='text-[0.9rem]'>Blockchain-Based Security</h3>
                    </div>
                    <div className='flex w-full gap-2 items-center'>
                        <img src={iconTick} className='w-[20px] opacity-50' alt="" />
                        <h3 className='text-[0.9rem]'>Decentralized Data Protection</h3>
                    </div>
                </div>


                {/* add plan button */}
                <div className='w-full flex items-center justify-center pt-5 px-5 py-2'>
                    {/* normal plan */}
                    <button disabled={plan_status2 || (plan_expire && duration && currentPlan)} onClick={onBtnClick} className={`w-full border-[1px] border-white/90 hover:bg-bethel-green/90  hover:scale-105 rounded-lg p-1 font-bold py-2
                        ${plan_status ? "animate-pulse bg-bethel-green" : "bg-black"}`}>
                        {currentPlan && duration ?
                            <div className={`w-full ${plan_expire ? "text-red-500" : "text-bethel-green"}`}>
                                {plan_expire ? "Expired" : "Activated"}
                            </div>
                            :
                            <div>
                                {!plan_active ?
                                    <div className='w-full'>
                                        {plan_status ? "LOADING ..." : "ADD PLAN"}
                                    </div> :
                                    <div className='w-full'>
                                        {plan_status ? "LOADING ..." : "DISABLED"}
                                    </div>}
                            </div>

                        }
                    </button>

                </div>



            </div>
        </div>
    )
}

export default PricePlanCard