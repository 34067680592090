import { createAction, createSlice } from "@reduxjs/toolkit";
export const revertBecx = createAction('REVERT_ALL')

const initialState = {
    price : ''
}

const becxSlice = createSlice({
    name : "becxSlice",
    initialState,
    extraReducers : (builder) => builder.addCase(revertBecx, () => initialState),
    reducers : {
        saveBECXPrice : (state,action) => action.payload
    }
})

export default becxSlice;