import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react'


function CardTable({ heading, iconCard, iconFile, }) {

    const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS; // contract address
    const fileStorageJSON = require("../../json/FileStorage.json");
    const fileStorageABI = fileStorageJSON.abi;
    const [singleFileDetails, setSingleFileDetails] = useState([]);

    // get files details from a smart contract
    const getAllFile = async () => {

        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                fileStorageAddress,
                fileStorageABI,
                signer
            );
            const res = await contract.getAllBatches();

            let SingleFileArr = [];
            let BatchFileArr = [];

            // section uploads into single or batch fn
            res.map((item, index) => {
                if (parseInt(item[2]._hex) === 1) {
                    SingleFileArr.push(item);
                } else {
                    BatchFileArr.push(item);
                }
            });
            setSingleFileDetails(SingleFileArr);
        } catch (error) {
            console.log(error)
        }


    };

    // single file name arr
    const [fileNames, setFileNames] = useState();

    const getIndividualFileName = async (index) => {
        const batchHash = singleFileDetails[index].BatchHash;
        try {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}get-filename?BatchHash=${batchHash}`);
            const fileName = await res.json();
            if(fileName !==null){
                setFileNames(prev => ({...prev, [index]: fileName[0].FileName }));
            }


        } catch (error) {
            console.error("Error fetching file name:", error);
        }
    };

    useEffect(() => {
        singleFileDetails.forEach((_, index) => {
            getIndividualFileName(index);
        });
    }, [singleFileDetails]);


    useEffect(() => {
        getAllFile()
    }, [])

    return (
        <div className='relative flex flex-col w-full h-[300px] bg-black border-bethel-green/50 border-[1px] p-2 rounded-lg'>
            {/* container image */}
            <div className='flex absolute p-2 bg-bethel-green rounded-full top-[-15px] left-[-1%]'>
                <img src={iconCard} className='w-[20px]' alt="" />
            </div>

            {/* heading text */}
            <div className='w-full p-2 mt-2'>
                <h3 className='text-[18px]'>{heading}</h3>
            </div>

            {/* table section */}
            <div>
                <table className="table w-full text-sm text-gray-400 border-separate border-transparent rounded-md border-spacing-2">
                    <thead className="text-white/50 bg-bethel-green/20 rounded-lg">
                        <tr className="flex justify-between w-full rounded-lg">
                            <th className="p-3 text-left">Name</th>
                            <th className="p-3 text-left"></th>
                        </tr>
                    </thead>

                    <tbody className='flex flex-col gap-1'>
                        {singleFileDetails && singleFileDetails.reverse().slice(0, 3).map((item, index) => {
                            return <tr className="flex items-center justify-between bg-bethel-green/10 animate__animated animate__fadeIn rounded-lg" key={index}>
                                <td className="">
                                    <div className="flex items-center justify-center px-3 ">
                                        <div className="flex items-center justify-center object-cover h-12 rounded-full w-full">
                                            <img src={iconFile} className='w-[20px]' alt="" /> <h3 className='ml-2'>{fileNames && fileNames[index]}</h3>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>

                    {/* end of the bulk upload menu */}
                </table>
            </div>
        </div >
    )
}

export default CardTable