import { createAction, createSlice } from "@reduxjs/toolkit";
export const revertRef = createAction('REVERT_ALL')

const initialState = {
    RefLink : ''
}

const refSlice = createSlice({
    name : "Ref",
    initialState,
    extraReducers : (builder) => builder.addCase(revertRef, () => initialState),
    reducers : {
        saveRefLink : (state,action) => action.payload
    }
})

export default refSlice;