/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useCallback, useEffect, useRef, useState } from "react";
import userImage from "../Images/icons/userIcon.png";
import iconRedDot from "../Images/icons/icon-reddot.png";
import iconGreenDot from "../Images/icons/icon-greenDot.png";
import notVerifyIcon from "../Images/icons/icons-close.png";
import { ethers } from "ethers";
import { useSelector } from "react-redux";

const KYCComponent = () => {
  const [KYCInfo, setKYCInfo] = useState(null);
  const [details, setDetails] = useState(null);

  const User_did = useSelector((state) => state.DidReducer); //get user did
  const KYCAddress = process.env.REACT_APP_KYC_CONTRACT_ADDRESS_APPROVE; // contract address
  const KYCjson = require("../json/KYC.json");
  const KYCabi = KYCjson.abi;

  const fileStorageAddress = process.env.REACT_APP_BLOCK_CHAIN_CONTRACT_ADDRESS; // contract address
  const fileStorageJSON = require("../json/FileStorage.json");
  const fileStorageABI = fileStorageJSON.abi;

  // update user details for the smart contract
  const updateContract = async (details) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(KYCAddress, KYCabi, signer);

    const res2 = await contract.saveKYCDetails(
      User_did,
      details.uuid,
      details.status
    );
  };

  // get kyc customer details from the smart contract
  const getKYCDataFromBlockChain = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(KYCAddress, KYCabi, signer);

    try {
      const res2 = await contract.getKYCDetails(User_did);
      setKYCInfo(res2);
    } catch (error) {
      console.log(error);
    }
  };

  // get code form the fractol id response
    const getKYCData = async (url) => {
      try {
        const res = await fetch(process.env.REACT_APP_BACKEND_URL + "kycstatus", {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            code: url,
          }),
        });

        const details = await res.json();

        updateContract(details);
      } catch (error) {
        console.log(error);
      }
    };

  // use for fractal id respons

  useEffect(() => {
    let uurl = window.location.href;
    getKYCDataFromBlockChain(); //get data from the block chain
    try {
      let url = uurl.slice(48, 91); //extract the code from the url
      getKYCData(url);
      console.log(url)
    } catch (error) {
      console.log(error)
    }
  }, []);

  return (
    <div>
      {/* kyc details head */}
      <div className="w-full flex bg-white/10 py-4 px-2 rounded-md justify-between items-center">
        <div className="flex flex-col text-white ">
          <h3 className="">KYC Verification Details</h3>
          {/* <h3>ID : 1234</h3> */}
        </div>

        {/* <button onClick={updateContract} className="text-white p-2 bg-red-50">
          KYC
        </button> */}

        {/* verified or not verified */}
        {KYCInfo && (KYCInfo[2] !== "pending") ? (
          <a
            href="https://bethel.bethel.network/api/v1/kyc"
            target="_blank"
            className="flex rounded-md bg-red-50 items-center gap-2 justify-center px-2"
            rel="noreferrer"
          >
            <div className="flex rounded-md bg-red-50 items-center gap-2 justify-center px-2 py-2">
              <h3 className="text-red-500">Click Here to Verify</h3>
            </div>
          </a>
        ) : (
          <div>
            <div className="flex rounded-md bg-green-50 items-center gap-2 justify-center px-2 ">
              <h3 className="text-green-500 font-bold ">Verified</h3>
            </div>
          </div>
        )}
      </div>
      {/* kyc body detaisl */}
      <div
        className="w-full flex  text-white items-center 
        lg:justify-between md:justify-between sm:justify-center min-[320px]:justify-center flex-wrap"
      >
        {/* details section */}
        <div className="flex items-center gap-2">
          {/* image section */}
          <div className="mt-2">
            <img src={userImage} className="w-[100px]" alt="" />
          </div>

          <div>
            <h3 className='text-[15px] mb-2'>ID: {KYCInfo && (KYCInfo[1] ? KYCInfo[1] : 'Not Verified')}</h3>
                    <h3 className='mb-2'>Status : {KYCInfo && (KYCInfo[2] === "pending" ? KYCInfo[2] : "Not Aprroved")}</h3>

            <div className="flex gap-0">
              <h3 className="">Liveness : </h3>
              <div className="flex gap-1 rounded-md px-1 font-bold justify-center items-center w-[70px]">
                <img src={iconGreenDot} className="w-[15px] h-[15px]" alt="" />
                <h3 className="text-green-500">Live</h3>
              </div>
            </div>
          </div>
        </div>
        {/* end of details section */}

        {/* start of verfication result section */}
        {/* <div className="flex flex-col gap-2 w-[300px] mt-2 border-l-[.1px] border-dashed px-4 bg-white/10 py-2">
          <h3>Verficication Result :</h3>
          <div className="flex bg-red-50 rounded-md px-1 w-[150px] items-center gap-2 justify-center">
            <img src={iconRedDot} className="w-[20px]" alt="" />
            <h3 className="text-red-500 font-bold ">Rejected</h3>
          </div>

          <div className="flex flex-col mt-2">
            <h3>Reson :</h3>
            <p className="text-[12px]">
              Some of Details are not correct Please Recheck and submit!
            </p>
          </div>
        </div> */}
        {/* end of verification result section */}

        {/* Show and hide iframe fractol id verfier */}

        {/* head of the User Detais */}
        <div className="w-full flex py-4 bg-white/10 px-2 mt-4 rounded-md">
          <h3>Customer Data </h3>
        </div>
      </div>
    </div>
  );
};

export default KYCComponent;
