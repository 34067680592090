import MainContent from './components/Main-Content';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Login from './components/Login_Register/Login';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import refSlice from './reducers/referralReducer';
// import VerifierSC from './components/Verifier-SC'

function App() {

    // rederect and get re
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [path , setPath] = useState("")
  
    useEffect(() => {
      if (location.pathname.includes('ref')) {
        const originalSearch = encodeURIComponent(location.search)
  
        // Redirect to the Login component with the original URL as query parameters
        setPath(originalSearch.slice(3 ,))
        dispatch(refSlice.actions.saveRefLink({RefLink : originalSearch.slice(3 ,)}))
      }
    }, [location, navigate]);
  return (
    <div className="App">
      {/* main router view */}
      <Routes>
        <Route path='/*' element={<MainContent />} />
        <Route path='/' element={<Login path={path && path} />} />
        {/* <Route path='/Verifier' element={<VerifierSC />} /> */}
      </Routes>
    </div >
  );
}

export default App;
