import React from 'react'
import { useSelector } from 'react-redux'
import iconSwap from "../Images/icons/icon-swap.png"
import iconSetting from "../Images/icons/icon-setting.png"
import iconBethel from "../Images/icons/bethel-logo.png"
import iconEther from "../Images/icons/icon-etherum.png"


function BethelAtomicSwap() {

    // checking purpose 
    const avalibilityData = useSelector((state) => state.requireAvalibilityReducer)

    return (
        <section className={`relative h-auto w-full flex flex-col justify-center ${!avalibilityData.allreadyRegisteredWallet && !avalibilityData.metamask && !avalibilityData.userAdded && !avalibilityData.noUser ? "" : "pointer-events-none grayscale blur-sm"}`}>
            {/* main flex */}
            <div className='flex flex-col'>

                {/*main topic */}
                <div className='w-full flex items-center px-2 gap-2 mt-4'>
                    <img src={iconSwap} className='w-[20px]' alt="" />
                    <h3 className='text-white uppercase font-normal'>Bethel Atomic Swap V1</h3>
                </div>

                {/* content div */}
                <div className='relative w-[500px] h-max flex-col flex items-center justify-start gap-2 p-4 rounded-lg  border border-white/30 mt-5'>

                    {/* live button */}
                    <div class="flex absolute right-2 top-3">
                        <div
                            class="text-[12px] w-[100px] bg-black border-yellow-500 border-[1px] flex justify-center items-center gap-1 rounded-lg p-1"
                        >
                            <h3 class="text-yellow-500 text-[10px]">Coming Soon</h3>
                            <div
                                class="flex w-[10px] h-[10px] rounded-full bg-yellow-500 shadow-[0_0_10px_5px_rgba(255,255,0,0.5),0_0_20px_10px_rgba(255,255,0,0.3)] animate-pulse"
                            ></div>
                        </div>
                    </div>

                    {/* swap container */}
                    <div className='flex p-2 rounded-full border border-bethel-green/40 gap-1 w-fit my-1 mt-8 text-[12px]'>
                        {/* semi containers  */}
                        <div className='flex w-[80px] bg-white/20 items-center justify-center p-2 rounded-full '>
                            <h3 className='text-white'>Swap</h3>
                        </div>

                        {/* semi containers  */}
                        <div className='flex w-[80px] bg-white/10 items-center justify-center p-2 rounded-full '>
                            <h3 className='text-white'>Limit</h3>
                        </div>

                        {/* semi containers  */}
                        <div className='flex w-[80px] bg-white/10 items-center justify-center p-2 rounded-full '>
                            <h3 className='text-white'>DCA</h3>
                        </div>

                        {/* semi containers  */}
                        <div className='flex w-[80px] bg-white/10 items-center justify-center p-2 rounded-full '>
                            <h3 className='text-white'>VA</h3>
                        </div>
                    </div>

                    <hr className='w-full opacity-20' />

                    {/* second dynamic and setting icon */}
                    <div className='flex justify-between px-2 w-full'>

                        {/* setting icon and dynamic  */}
                        <div className='flex p-2 gap-2'>
                            <div className='bg-white/10 rounded-full p-2'>
                                <img src={iconSetting} className='w-[20px]' alt="" />
                            </div>

                            {/*dynmic */}
                            <div className='bg-white/10 rounded-full p-2'>
                                <h3 className='text-white text-[12px]'>Dynamic</h3>
                            </div>
                        </div>

                        {/* refresh  */}
                        <div className='flex p-2 gap-2'>
                            <div className='bg-white/10 rounded-full p-2'>
                                <img src={iconSetting} className='w-[20px]' alt="" />
                            </div>
                        </div>

                    </div>

                    {/* slling dic */}
                    <div className='flex flex-col w-full p-4 bg-white/10 rounded-lg my-2 gap-4'>
                        {/* content div */}
                        <div className='flex w-full'>
                            <h3 className='text-white text-[18px] font-semibold'>You are Selling</h3>
                        </div>

                        {/* selling token div  */}
                        <div className='flex justify-between w-full px-2 relative'>
                            <div className='flex w-[120px] rounded-xl p-3 bg-[#0F0F0F] gap-2 items-center justify-center'>
                                <img src={iconBethel} className='w-[20px]' alt="" />
                                <h2 className='text-white font-bold text-[14px]'>BETHEL</h2>
                            </div>

                            <div className='flex w-[100px] rounded-xl p-3 bg-[#0F0F0F] gap-2 items-center justify-center'>
                                <h2 className='text-white/50 font-bold text-[19px]'>0.00</h2>
                            </div>
                        </div>
                    </div>

                    {/* buying dic */}
                    <div className='flex flex-col w-full p-4 bg-white/10 rounded-lg my-2 gap-4'>
                        {/* content div */}
                        <div className='flex w-full'>
                            <h3 className='text-white text-[18px] font-semibold'>You are Buying</h3>
                        </div>

                        {/* selling token div  */}
                        <div className='flex justify-between w-full px-2 relative'>
                            <div className='flex w-[120px] rounded-xl p-3 bg-[#0F0F0F] gap-2 items-center justify-center'>
                                <img src={iconEther} className='w-[20px]' alt="" />
                                <h2 className='text-white font-bold text-[14px]'>ETHER</h2>
                            </div>

                            <div className='flex w-[100px] rounded-xl p-3 bg-[#0F0F0F] gap-2 items-center justify-center'>
                                <h2 className='text-white/50 font-bold text-[19px]'>0.00</h2>
                            </div>
                        </div>
                    </div>

                    <button className='w-full p-4 hover:bg-bethel-green/70 text-white font-semibold border-white/50 border rounded-lg text-center justify-center items-center'>
                        CONNECT WALLET
                    </button>

                </div>
            </div>


        </section >
    )
}

export default BethelAtomicSwap