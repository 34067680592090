import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    points : 0,
    is_added : false,
    totalPoints : 0
}

export const filePointsSlice = createSlice({
    name : "FilePointSlice",
    initialState,
    reducers : {
        savePoints : (state, action) => action.payload
    }
})

export default filePointsSlice