import React from 'react'
import ErrorImage from "../../Images/Dashboard-images/3804933-ai.png"
import { Link } from 'react-router-dom'

const ErrorPage404 = () => {
  return (
    <div className=''>
        <div className='flex flex-col w-full items-center justify-center text-center'>
            <h3 className='text-bethel-green font-bold text-[50px]'>Oooops! <br /> ErrorPage 404</h3>
            <h3 className='text-white text-[20px]'><i>Please go to the Home Page <Link to="/dashboard" ><u className='text-bethel-green'>here</u></Link></i></h3>
            <img src={ErrorImage} className="w-[500px]" alt="" />
        </div>
    </div>
  )
}


export default ErrorPage404
