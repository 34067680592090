import { useGLTF, useAnimations } from "@react-three/drei";
import { useEffect } from "react";

export default function Scene(props) {
  const { scene, animations } = useGLTF("./BethelLogo23.glb");
  const { actions } = useAnimations(animations, scene);

  useEffect(() => {
    if (actions) {
      actions['Animation'].play(); 
    }
  }, [actions]);

  return (
    <mesh >
      <primitive object={scene} />
    </mesh>
  )
}
