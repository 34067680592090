import { createAction, createSlice } from "@reduxjs/toolkit";
export const revertAvailibilty = createAction('REVERT_ALL')

const initialState = {
    metamask : false,
    userAdded : false,
    noUser : false,
    allreadyRegisteredWallet : false
}

const requireAvalibilitySlice = createSlice({
    name : "requireAvalibilitySlice",
    initialState,
    extraReducers : (builder) => builder.addCase(revertAvailibilty, () => initialState),
    reducers : {
        saveAvailibility : (state,action) => action.payload
    }
})

export default requireAvalibilitySlice;