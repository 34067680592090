import React, { useState } from 'react'
import token2049 from "../Images/BG-Images/token2049.png"
import coupon from "../Images/BG-Images/image2.png"
import bethelLogo from "../Images/BG-Images/bethel-new-logo.png"
import { ethers } from 'ethers'
import { useSelector } from 'react-redux'

function Token2049Coupon({ addCoupon, onCouponCodeChange, couponProcess, closeBtn }) {
    const [couponCode, setCouponCode] = useState(null)

    const handlePaste = (e) => {
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData("text");

        // Here we'll need to simulate a change event for the input manually
        const updatedValue = pastedText;
        setCouponCode(updatedValue);
        console.log(updatedValue)
        onCouponCodeChange(updatedValue); // Call the parent's function to update state

    }

    const handleChange = (e) => {
       const value = e.target.value
       onCouponCodeChange(value)
    }

    // is user already added 



    return (
        <div className='w-[800px] fixed lg:top-1/3 transform lg:-translate-x-1/2 lg:-translate-y-1/2 lg:left-1/2 md:-left-20 md:top-1/4 ml-20 scale-75 z-[9999999] animate__animated animate__fadeIn'>
            <img src={coupon} alt='' className='flex rounded-lg opacity-100' />
            {/* bethel logo */}
            <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2  p-2 rounded-full'>
                <img src={bethelLogo} className='flex w-[300px] opacity-20' alt="" />
            </div>

            {/* text above left corner */}
            <div className='absolute -top-10 left-2 z-[1] bg-black px-2'>
                <h3 className='text-bethel-green font-bold text-[24px]'>Bethel Coupon</h3>
            </div>

            <hr className='absolute -top-5 left-5 z-[0] bg-white h-[2px] w-[95%]' />

            {/* topic section */}
            <div className='text-black absolute top-6 w-full flex justify-center flex-col items-center gap-0 leading-10 mt-5'>
                <div className="text-[44px] flex items-center gap-5 ">
                    <img src={bethelLogo} className='w-[40px]' alt="" />
                    <h1 className=' font-bold tracking-wide text-bethel-green'>BETHEL</h1>
                    <h1 className=' tracking-wide text-white/50'>x</h1>
                    <h1 className=' font-bold tracking-wide bg-gradient-to-r from-purple-400 to-blue-400 bg-clip-text text-transparent'>TOKEN 2049</h1>
                </div>
                <h1 className='text-[22px] font-semibold tracking-widest text-white'>SINGAPORE</h1>

                <hr className='w-[80%] text-black bg-black/30 h-[1px]' />

                <div className='w-full justify-center flex items-center'>
                    <h3 className='text-green-600 font-semibold tracking-wider text-[18px]'>✨ Get 1GB of Free Storage on Bethel ZKP Storage! ✨</h3>
                </div>

                <div className='flex justify-center w-[80%] items-center text-center'>
                    <h3 className='text-white leading-6 text-[14px]'>As part of our TOKEN2049 event, we're excited to offer you 1GB of free, secure storage on our cutting-edge Bethel ZKP Storage platform.</h3>
                </div>

                <div className='flex justify-center w-[80%] items-center text-center mt-3 gap-2 '>
                    <button onClick={addCoupon} className='text-bethel-newgreen leading-6 text-[14px] p-2 border border-white px-2 font-bold rounded-lg'>{couponProcess.is_adding ? "Loading" : "Sumbit"}</button>
                    <input onPaste={handlePaste} onChange={handleChange} type="text" className='bg-bethel-newgreen/30 border border-bethel-green/50 w-[60%] placeholder:text-white text-white px-2 rounded-md' placeholder='Enter coupon code here' />
                </div>

                <div className='flex justify-center w-[80%] items-center text-center mt-2'>
                    <a href="https://bethelnet.io/"><h3 className='text-white leading-6 text-[14px]'><u>learn more</u></h3></a>
                </div>

                <div className=''>
                    { couponProcess.is_adding && <h3 className='text-green-500 animate-pulse font-bold'>Coupon adding ...</h3> }
                    { couponProcess.is_complete && <h3 className='text-green-500 animate-pulse'>Complete</h3> }
                    { couponProcess.fail && <h3 className='text-red-500 animate-pulse font-bold'>Already Added !</h3> }
                </div>

            </div>


            <div className='absolute bottom-2 right-10 flex '>
                <h3 className='text-white text-sm'>Valid Until | 16th Sept - 16th Oct</h3>
            </div>

            {/* close button */}
            <button onClick={closeBtn} className='w-12 h-12 border-2 border-red-600 rounded-full absolute -top-20 right-0 text-white hover:bg-red-500 font-bold'>
                x
            </button>





        </div>
    )
}

export default Token2049Coupon