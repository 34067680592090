import React from 'react'
import iconRightArrow from '../../Images/icons/icons8-right-arrow-90.png'

function ProfileTitleCard({ title, Maintitle, Subtitle, Image, CardColor}) {
  return (
    <button className={`w-full flex p-2 flex-col ${CardColor ? 'bg-bethel-green/30' : 'bg-black border-bethel-green/50 border-[1px]' } rounded-lg justify-between gap-1`}>
      {/* title and arrow */}
      <div className='flex w-full items-center justify-between px-2 py-1 lg:flex md:flex sm:flex min-[320px]:hidden'>
        <div className=' flex gap-2 px-3 py-1 rounded-xl bg-white/10 '>
          <h3 className='text-white text-[10px]'>{title}</h3>
          <img src={iconRightArrow} className='w-[10px]' alt="" />
        </div>

      </div>


      {/* main title  */}

      <div className='flex w-full items-center gap-2 px-2 py-2'>
        {/* main image */}
        <div className='flex p-1 rounded-full border-white border-[1px]'>
          <img src={Image} className='w-[20px]' alt="" />
        </div>

        {/* discription card */}
        <div className='flex w-full flex-col justify-start text-start'>
          <h3 className='text-white text-[12px]'>{Maintitle}</h3>
          <h3 className='text-white text-[8px]'>{Subtitle}</h3>
        </div>

      </div>

    </button> 
  )
}

export default ProfileTitleCard